import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import "./Dropdown.css";
import Modal from "./Modal"; // Import the Modal component

function Dropdown() {
  const [institutes, setInstitutes] = useState([]);
  const [filteredInstitutes, setFilteredInstitutes] = useState([]);
  const [instituteTypes, setInstituteTypes] = useState([]);
  const [instituteNames, setInstituteNames] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();

  const getUser = async () => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/auth2/login/success`;
      const { data } = await axios.get(url, { withCredentials: true });
      if (data && data.user) {
        setUser(data.user._json);
        return true; // User is valid
      } else {
        handleLogout();
        return false; // User is invalid
      }
    } catch (err) {
      console.log(err);
      handleLogout();
      return false; // User is invalid
    }
  };

  useEffect(() => {
    // Fetch institutes data
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/institutes`)
      .then((response) => {
        const data = response.data;
        setInstitutes(data);
        setFilteredInstitutes(data);

        const types = [...new Set(data.map((item) => item.institute_type))];
        setInstituteTypes(types);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  useEffect(() => {
    if (selectedType && institutes.length > 0) {
      const filteredNames = [
        ...new Set(
          institutes
            .filter((item) => item.institute_type === selectedType)
            .map((item) => item.institute_name)
        ),
      ];
      setInstituteNames(filteredNames);
      setSelectedName("");
      setBranches([]);
      setSelectedBranches([]);
    } else {
      setInstituteNames([]);
      setBranches([]);
      setSelectedName("");
      setSelectedBranches([]);
    }
  }, [selectedType, institutes]);

  useEffect(() => {
    if (selectedName && institutes.length > 0) {
      const filteredBranches = [
        ...new Set(
          institutes
            .filter((item) => item.institute_name === selectedName)
            .map((item) => item.branch)
        ),
      ];
      console.log(filteredBranches);
      setBranches(filteredBranches);
      setSelectedBranches([]);
    } else {
      setBranches([]);
      setSelectedBranches([]);
    }
  }, [selectedName, institutes]);

  useEffect(() => {
    if (institutes.length > 0) {
      let filtered = institutes;
      if (selectedType)
        filtered = filtered.filter(
          (institute) => institute.institute_type === selectedType
        );
      if (selectedName)
        filtered = filtered.filter(
          (institute) => institute.institute_name === selectedName
        );
      if (selectedBranches.length > 0)
        filtered = filtered.filter((institute) =>
          selectedBranches.includes(institute.branch)
        );

      setFilteredInstitutes(filtered);
    }
  }, [selectedType, selectedName, selectedBranches, institutes]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleNameChange = (e) => {
    setSelectedName(e.target.value);
  };

  const handleBranchChange = (selectedOptions) => {
    setSelectedBranches(selectedOptions.map((option) => option.value));
  };

  const handleSubmit = async () => {
    // console.log('Submiit button clicked');
    const userIsValid = await getUser();
    if (
      userIsValid &&
      selectedType &&
      selectedName &&
      selectedBranches.length > 0
    ) {
      console.log("inside the if handle Submiit button clicked");
      navigate(
        `/contacts_outside?type=${selectedType}&name=${selectedName}&branches=${selectedBranches.join(
          ","
        )}`
      );
    }
    else {
      setModalMessage("Your session expired.");
      setIsModalOpen(true);
    }
  };

  const handleLogout = () => {
    setModalMessage("You have been logged out.");
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/logout`;
  };

  // Prepare options for react-select
  const branchOptions = branches.map((branch) => ({
    value: branch,
    label: branch,
  }));

  // Custom styles for react-select
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      padding: "8px",
      fontSize: "16px",
      borderRadius: "8px",
      border: "1px solid #007bff",
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
      backgroundColor: "white",
      color: "#333",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      color: state.isSelected ? "white" : "#333",
      padding: "10px",
      fontSize: "16px",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#007bff",
      color: "white",
      borderRadius: "4px",
      padding: "2px 6px",
      margin: "2px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
      fontSize: "14px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
      ":hover": {
        backgroundColor: "#0056b3",
        color: "white",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  return (
    <div className="dropdown-container">
      <div className="logout-button-container">
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>
      <h1 className="heading">Institutes Table</h1>

      <div className="filters">
        <select
          onChange={handleTypeChange}
          value={selectedType}
          className="filter-select"
        >
          <option value="">Select Institute Type</option>
          {instituteTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        <select
          onChange={handleNameChange}
          value={selectedName}
          className="filter-select"
          disabled={!selectedType}
        >
          <option value="">Select Institute Name</option>
          {instituteNames.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>

        <div className="branch-filter">
          <span>Select Branches:</span>
          <Select
            isMulti
            options={branchOptions}
            onChange={handleBranchChange}
            value={branchOptions.filter((option) =>
              selectedBranches.includes(option.value)
            )}
            styles={customSelectStyles} // Apply custom styles
            className="branch-select"
            classNamePrefix="react-select" // Prefix for custom class names
          />
        </div>
      </div>

      <button
        onClick={handleSubmit}
        className="submit-button"
        disabled={
          !selectedType || !selectedName || selectedBranches.length === 0
        }
      >
        Submit
      </button>

      <table className="institutes-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Institute Type</th>
            <th>Institute Name</th>
            <th>Branch</th>
          </tr>
        </thead>
        <tbody>
          {filteredInstitutes.map((institute) => (
            <tr key={institute.id}>
              <td>{institute.id}</td>
              <td>{institute.institute_type}</td>
              <td>{institute.institute_name}</td>
              <td>{institute.branch}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        message={modalMessage}
        autoClose={true} // Set autoClose to true
        autoCloseDuration={1000} // Set duration to 5 seconds
      />
    </div>
  );
}

export default Dropdown;
