import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./Contacts.css";
import Modal from "./Modal"; // Import the Modal component

function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [flippedCardId, setFlippedCardId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [branches, setBranches] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // Function to get user data
  const getUser = async () => {
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/auth/login/success`;
      const { data } = await axios.get(url, { withCredentials: true });
      if (data && data.user) {
        setUser(data.user._json);
        return data.user._json.email;
      } else {
        handleLogout();
        return null;
      }
    } catch (err) {
      console.log(err);
      console.log("patience");
      handleLogout();
      return null;
    }
  };

  // Function to fetch email-related details
  const fetchEmailDetails = async (userEmail) => {
    try {
      const emailCheckResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/check-email?email=${userEmail}`
      );

      if (emailCheckResponse.data.exists) {
        setType(emailCheckResponse.data.type);
        setName(emailCheckResponse.data.name);
      } else {
        handleLogout();
      }
    } catch (error) {
      console.error("Error fetching email details ----", error);
    }
  };

  // useEffect to fetch user and contacts data
  useEffect(() => {
    const fetchUserAndContacts = async () => {
      const userEmail = await getUser();
      if (userEmail) {
        await fetchEmailDetails(userEmail);

        if (type && name) {
          try {
            const contactsResponse = await axios.get(
              `${process.env.REACT_APP_SERVER_URL}/contacts`,
              {
                params: {
                  type,
                  name,
                  branches,
                },
              }
            );
            setContacts(contactsResponse.data);
          } catch (error) {
            console.error("There was an error fetching the contacts! heee", error);
          }
        }
      }
    };

    fetchUserAndContacts();
  }, [type, name, branches]);

  // Handle card click with user validation
  const handleCardClick = async (id) => {
    const userValid = await getUser();
    if (userValid) {
      setFlippedCardId((prevId) => (prevId === id ? null : id));
    } else {
      setModalMessage("Your session expired.");
      setIsModalOpen(true);
    }
  };

  // Logout function
  const handleLogout = () => {
    setModalMessage("You have been logged out.");
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/logout`;
  };

  return (
    <div className="contacts-container">
      <div className="logout-button-container">
        <button onClick={handleLogout} className="logout-button">
          Logout
        </button>
      </div>
      <h1 className="heading">Contacts</h1>
      <div className="contacts-grid">
        {contacts.map((contact) => (
          <div
            key={contact.id}
            className={`contact-card ${
              flippedCardId === contact.id ? "flipped" : ""
            }`}
            onClick={() => handleCardClick(contact.id)}
          >
            <div className="contact-card-inner">
              <div className="contact-card-front">
                <div className="profile-photo">
                  <img
                    src={`https://ui-avatars.com/api/?name=${contact.name}`}
                    alt={contact.name}
                  />
                </div>
                <div className="contact-details">
                  <h2 className="contact-name">{contact.name}</h2>
                  <p className="contact-institute">
                    {contact.institute_name}
                  </p>
                  <p className="contact-branch">{contact.branch}</p>
                </div>
              </div>
              <div className="contact-card-back">
                <p className="contact-phone">{contact.phone}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        message={modalMessage}
        autoClose={true} // Set autoClose to true
        autoCloseDuration={1000} // Set duration to 5 seconds
      />
    </div>
  );
}

export default Contacts;
