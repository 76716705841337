import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./RegistrationForm.css";

function RegistrationForm2() {
  const [enrollment_number, setEnrollment] = useState("");
  const [email, setGmail] = useState(""); // This will be used to display the email

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [formVisible, setFormVisible] = useState(true);

  const navigate = useNavigate();

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/auth2/login/success`,
          { withCredentials: true }
        );
        if (data && data.user) {
          setUser(data.user._json);
          setGmail(data.user._json.email); // Set the gmail state to user's email
        } else {
          handleLogout();
        }
      } catch (err) {
        console.log(err);
        handleLogout();
      }
    };

    fetchUser();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!user) {
      setError("User not found. Please log in.");
      setLoading(false);
      return;
    }

    const contactDetails = {
      email,
      password: "google", // Set password as "google"
      enrollment_number,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/student_details`,
        contactDetails
      );
      const { redirectTo } = response.data;
      alert("Contact added successfully!");
      navigate(redirectTo);
    } catch (error) {
      console.error("There was an error adding the contact!", error);
      setError("There was an error adding the contact.");
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth2/logout`;
  };

  return (
    <div className="registration-form-container">
      <h1 className="heading">Register Contact</h1>
      {formVisible && (
        <form onSubmit={handleSubmit} className="registration-form">
          <label>
            <span>Email:</span>
            <input
              type="email"
              value={email} // Display email that was fetched
              readOnly // Make it read-only if you don't want the user to change it
            />
          </label>
          <label>
            <span>JEE Mains Enrollment Number:</span>
            <input
            //   type="email" // Correct type for email input
              value={enrollment_number} // Display
              onChange={(e) => setEnrollment(e.target.value)}
              required
            />
          </label>
          <button type="submit" disabled={loading}>
            {loading ? "Submitting..." : "Submit"}
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>
      )}
    </div>
  );
}

export default RegistrationForm2;
