import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./RegistrationForm.css";
import Modal2 from "./Modal2";

function RegistrationForm() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [instituteType, setInstituteType] = useState("");
  const [instituteName, setInstituteName] = useState("");
  const [branch, setBranch] = useState("");
  const [gmail, setGmail] = useState(""); // This will be used to display the email
  const [personal_email, setPersonal_email] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [instituteTypes, setInstituteTypes] = useState([]);
  const [instituteNames, setInstituteNames] = useState([]);
  const [branches, setBranches] = useState([]);
  const [institutes, setInstitutes] = useState([]);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [formVisible, setFormVisible] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  const requestOTP = async () => {
    setOtpLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/request-otp`, {
        email: personal_email,
      });
      setOtpSent(true);
      setOtpError("");
    } catch (error) {
      setOtpError(error.response?.data?.message || "Error requesting OTP.");
    } finally {
      setOtpLoading(false);
    }
  };

  const verifyOTP = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/verify-otp`, {
        email: personal_email,
        otp,
      });
      if (response.data.message === "Email verified successfully!") {
        setIsEmailVerified(true);
        setOtpError("");
      } else {
        setOtpError(response.data.message);
      }
    } catch (error) {
      setOtpError(error.response?.data?.message || "Error verifying OTP.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch user details on component mount
    const fetchUser = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/auth/login/success`,
          { withCredentials: true }
        );
        if (data && data.user) {
          setUser(data.user._json);
          setGmail(data.user._json.email); // Set the gmail state to user's email
        } else {
          handleLogout();
        }
      } catch (err) {
        console.log(err);
        handleLogout();
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    // Fetch institutes data
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/institutes`)
      .then((response) => {
        const data = response.data;
        setInstitutes(data);

        const types = [...new Set(data.map((item) => item.institute_type))];
        setInstituteTypes(types);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  useEffect(() => {
    if (instituteType && institutes.length > 0) {
      const filteredNames = [
        ...new Set(
          institutes
            .filter((item) => item.institute_type === instituteType)
            .map((item) => item.institute_name)
        ),
      ];
      setInstituteNames(filteredNames);
      setInstituteName("");
      setBranches([]);
    } else {
      setInstituteNames([]);
      setBranches([]);
    }
  }, [instituteType, institutes]);

  useEffect(() => {
    if (instituteName && institutes.length > 0) {
      const filteredBranches = [
        ...new Set(
          institutes
            .filter((item) => item.institute_name === instituteName)
            .map((item) => item.branch)
        ),
      ];
      setBranches(filteredBranches);
      setBranch("");
    } else {
      setBranches([]);
    }
  }, [instituteName, institutes]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!user) {
      setError("User not found. Please log in.");
      setLoading(false);
      return;
    }

    const contactDetails = {
      name,
      phone,
      institute_name: instituteName,
      institute_type: instituteType,
      branch,
      gmail,
      password: "google", // Set password as "google"
      personal_email,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/ug_details`,
        contactDetails
      );
      const { redirectTo } = response.data;
      alert("Contact added successfully!");
      navigate(redirectTo);
    } catch (error) {
      console.error("There was an error adding the contact!", error);
      setError("There was an error adding the contact.");
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/logout`;
  };

  return (
    <div className="registration-form-container">
      <div className="header">
        <img src="/images/fav.ico" alt="Registration Header" className="header-image" />
        <p className="header-text">We are glad to have you on board</p>
      </div>
      {formVisible && (
        <form onSubmit={handleSubmit} className="registration-form">
          <label>
            <span>Name:</span>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </label>
          <label>
            <span>Phone:</span>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </label>
          <label>
            <span>Institute Type:</span>
            <select
              value={instituteType}
              onChange={(e) => setInstituteType(e.target.value)}
              required
            >
              <option value="">Select Institute Type</option>
              {instituteTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </label>
          <label>
            <span>Institute Name:</span>
            <select
              value={instituteName}
              onChange={(e) => setInstituteName(e.target.value)}
              required
              disabled={!instituteType}
            >
              <option value="">Select Institute Name</option>
              {instituteNames.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </label>
          <label>
            <span>Branch:</span>
            <select
              value={branch}
              onChange={(e) => setBranch(e.target.value)}
              required
              disabled={!instituteName}
            >
              <option value="">Select Branch</option>
              {branches.map((branchName) => (
                <option key={branchName} value={branchName}>
                  {branchName}
                </option>
              ))}
            </select>
          </label>
          <label>
            <span>Email:</span>
            <input
              type="email"
              value={gmail} // Display email that was fetched
              readOnly // Make it read-only if you don't want the user to change it
            />
          </label>
          <label>
            <span>Personal Email:</span>
            <input
              type="email" // Correct type for email input
              value={personal_email}
              onChange={(e) => setPersonal_email(e.target.value)}
              required
            />
            {!isEmailVerified && (
              <button
                type="button"
                onClick={() => setModalVisible(true)} // Open the modal
                disabled={!personal_email}
              >
                Verify Email
              </button>
            )}
          </label>
          {isEmailVerified && (
            <p className="otp-verified-message">OTP verified successfully!</p>
          )}
          <button type="submit" disabled={!isEmailVerified || loading}>
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      )}
      {error && <p className="error-message">{error}</p>}
      <Modal2
        isVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        onRequestOTP={requestOTP}
        onVerifyOTP={verifyOTP}
        otp={otp}
        setOtp={setOtp}
        otpSent={otpSent}
        otpLoading={otpLoading}
        otpError={otpError}
        isEmailVerified={isEmailVerified}
      />
    </div>
  );
}

export default RegistrationForm;
