import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Dropdown from "./components/Dropdown";
import React from "react";
import Contacts from "./components/Contacts";
import HomePage from "./components/HomePage";
import RegistrationForm from "./components/RegistrationForm";
import RegistrationForm2 from "./components/RegistrationForm2";
import ContactsOutside from "./components/ContactsOutside";
function App() {
	const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);    
	const getUser = async () => {
		try {
      const url = `${process.env.REACT_APP_SERVER_URL}/auth/login/success`;
      const { data } = await axios.get(url, { withCredentials: true });
      setUser(data.user._json);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); 
    }
	};

	useEffect(() => {
		getUser();
	}, []);
if (loading) {
  // Optionally render a loading spinner or placeholder
  return <div>Loading...</div>;
}
	return (
    <div className="container">
      <Routes>
        <Route
          path="/dropdown"
          element={user ? <Dropdown /> : <Navigate to="/" />}
        /> 
        
        <Route path="/" element={<HomePage />} />
        {/* <Route
          path="/login"
          element={user ? <Navigate to="/contacts" /> : <SignInUg />}
        /> */}
        <Route
          path="/contacts"
          element={user ? <Contacts /> : <Navigate to="/" />}
        />
        <Route
          path="/register_ug"
          element={user ? <RegistrationForm /> : <Navigate to="/" />}
        />
        <Route
          path="/register_student"
          element={
            user ? <RegistrationForm2 /> : <Navigate to="/" />
          }
        />
        {/* <Route
          path="/signin_student"
          element={user ? <Navigate to="/dropdown" /> : <SignInStudent />}
        /> */}
        <Route
          path="/contacts_outside"
          element={user ? <ContactsOutside /> : <Navigate to="/" /> }
        />
      </Routes>
    </div>
  );
}

export default App;
