import React from "react";
import "./HomePage.css";
import axios from 'axios';

function HomePage() {
  const handleGoogleSignInUG = () => {
    window.open(`${process.env.REACT_APP_SERVER_URL}/auth/google/callback`, "_self");
  };

  const handleGoogleSignInStudent = () => {
    window.open(`${process.env.REACT_APP_SERVER_URL}/auth2/google/callback`, "_self");
  };

  const handleAboutUs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/about-us`);
      alert(response.data); // Show the paragraph text in an alert
    } catch (error) {
      console.error('Error fetching the About Us paragraph:', error);
      alert('Failed to fetch About Us information. Please try again later.'); // Alert on error
    }
  };

  const handleGoal = () => {
    console.log("Goal");
  };

  // Generate image elements dynamically
  const logoCount = 15;
  const logos = Array.from({ length: logoCount }, (_, index) => (
    <img
      key={index}
      src={`/images/logos/logo${index + 1}.png`}
      alt={`Logo ${index + 1}`}
    />
  ));

  // Duplicate logos for seamless scrolling effect
  const duplicatedLogos = [
    ...logos, ...logos, ...logos, ...logos, ...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos, ...logos, ...logos, ...logos, ...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos,
    ...logos, ...logos, ...logos, ...logos, ...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos, ...logos, ...logos, ...logos, ...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos,...logos
  ];

  return (
    <div className="total">
      <header className="homepage-header">
        <div className="logo-container">
          <video autoPlay loop muted className="logo-video">
            <source src="/videos/logo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1>ConnectGrad</h1>
        </div>
        <div className="header-buttons">
          <button onClick={handleAboutUs} className="header-button">
            About Us
          </button>
          <button onClick={handleGoal} className="header-button">
            Contact Us
          </button>
        </div>
      </header>
      <main className="homepage-main">
        <div className="content-container">
          <div className="image-container">
            <img src="/images/green1.png" alt="Description of image" />
          </div>
          <div className="card">
            <div className="card-group">
              <p className="card-paragraph">
                Inspire and Lead the Next Gen as a current university student
              </p>
              <button onClick={handleGoogleSignInUG} className="homepage-button-1">
                UG Sign in
              </button>
            </div>
            <div className="card-group">
              <p className="card-paragraph">
                Get Personalized Guidance for Your University Journey as a high school graduate
              </p>
              <button
                onClick={handleGoogleSignInStudent}
                className="homepage-button-2"
              >
                Student Sign in
              </button>
            </div>
          </div>
        </div>
      </main>
      <footer className="footer">
        <div className="logo-carousel">
          {duplicatedLogos}
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
