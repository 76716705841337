import React, { useEffect, useRef } from 'react';
import './Modal2.css';

const Modal2 = ({
  isVisible, 
  onClose, 
  onRequestOTP, 
  onVerifyOTP, 
  otp, 
  setOtp, 
  otpSent, 
  otpLoading, 
  otpError, 
  isEmailVerified, 
  setIsEmailVerified 
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onClose]);

  const handleVerifyOTP = () => {
    onVerifyOTP();
    if (otpError) {
      return;
    }
    if (isEmailVerified) {
      onClose();
    }
  };

  return (
    isVisible ? (
      <div className="modal-overlay">
        <div className="modal-content" ref={modalRef}>
          <div className="close-container" onClick={onClose}>
            <div className="leftright"></div>
            <div className="rightleft"></div>
            <label className="close">close</label>
          </div>
          <h2>OTP Verification</h2>
          {!isEmailVerified ? (
            <>
              {!otpSent ? (
                <div>
                  <button
                    type="button"
                    onClick={onRequestOTP}
                    disabled={otpLoading}
                  >
                    {otpLoading ? "Sending OTP..." : "Send OTP"}
                  </button>
                  {otpError && <p className="error-message">{otpError}</p>}
                </div>
              ) : (
                <div>
                  <label>
                    <span>Enter OTP:</span>
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      maxLength={6}
                      required
                    />
                  </label>
                  <button
                    type="button"
                    onClick={handleVerifyOTP}
                    disabled={otp.length !== 6 || otpLoading}
                  >
                    {otpLoading ? "Verifying..." : "Verify OTP"}
                  </button>
                  {otpError && (
                    <div>
                      <p className="error-message">{otpError}</p>
                      <button
                        type="button"
                        onClick={onRequestOTP}
                        disabled={otpLoading}
                      >
                        Resend OTP
                      </button>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <p className="otp-verified-message">OTP verified successfully!</p>
          )}
        </div>
      </div>
    ) : null
  );
};

export default Modal2;
